import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Profile from './Profile';
import LearningManagementSystem from './LearningManagementSystem';
import NAAC from './NAAC';
import Reports from './Reports';
import Parct1 from './pages/pract1';
import AddUser from './Crud/Add';
import EditUser from './Crud/Edit';
import DeleteUser from './Crud/Delete';
import ExportUsers from './Crud/Export';
import Viewcourse from './pages/Viewcourse';
import Viewcourse1 from './pages/Viewcourse1';
import Login from './pages/Login';
import Dashmcas11 from './pages/Dashmcas11';

import Dashmncas11 from './pages/Dashmncas11';
import Dashmncas11admin from './pages/Dashmncas11admin';
import Dashmncas12 from './pages/Dashmncas12';
import Dashmncas12admin from './pages/Dashmncas12admin';

import Dashmncas22 from './pages/Dashmncas22';
import Dashmncas22admin from './pages/Dashmncas22admin';
import Dashmncas23 from './pages/Dashmncas23';
import Dashmncas23admin from './pages/Dashmncas23admin';
import Dashmncas241 from './pages/Dashmncas241';
import Dashmncas241admin from './pages/Dashmncas241admin';
import Dashmncas242 from './pages/Dashmncas242';
import Dashmncas242admin from './pages/Dashmncas242admin';
import Dashmncas243 from './pages/Dashmncas243';
import Dashmncas243admin from './pages/Dashmncas243admin';
import Dashmncas251 from './pages/Dashmncas251';
import Dashmncas251admin from './pages/Dashmncas251admin';
import Dashmncas252 from './pages/Dashmncas252';
import Dashmncas252admin from './pages/Dashmncas252admin';
import Dashmncas253 from './pages/Dashmncas253';
import Dashmncas253admin from './pages/Dashmncas253admin';
import Dashmncas26 from './pages/Dashmncas26';
import Dashmncas26admin from './pages/Dashmncas26admin';

import Dashmprojects from './pages/Dashmprojects';
import Dashmprojectsadmin from './pages/Dashmprojectsadmin';

import Dashmpublications from './pages/Dashmpublications';
import Dashmpublicationsadmin from './pages/Dashmpublicationsadmin';
import Dashmpatents from './pages/Dashmpatents';
import Dashmpatentsadmin from './pages/Dashmpatentsadmin';
import Dashmteacherfellow from './pages/Dashmteacherfellow';
import Dashmteacherfellowadmin from './pages/Dashmteacherfellowadmin';
import Dashmconsultancy from './pages/Dashmconsultancy';
import Dashmconsultancyadmin from './pages/Dashmconsultancyadmin';
import Dashmphdguide from './pages/Dashmphdguide';
import Dashmphdguideadmin from './pages/Dashmphdguideadmin';
import Dashmseminar from './pages/Dashmseminar';
import Dashmseminaradmin from './pages/Dashmseminaradmin';
import Dashmbook from './pages/Dashmbook';
import Dashmbookadmin from './pages/Dashmbookadmin';

import Dashmexamschedule from './pages/Dashmexamschedule';
import Dashmexamscheduleadmin from './pages/Dashmexamscheduleadmin';
import Dashmexamroom from './pages/Dashmexamroom';
import Dashmexamroomadmin from './pages/Dashmexamroomadmin';

import Loginstud from './pages/Loginstud';

import Dashmmprograms from './pages/Dashmmprograms';
import Dashmmprogramsadmin from './pages/Dashmmprogramsadmin';
import Dashmmcourses from './pages/Dashmmcourses';
import Dashmmcoursesadmin from './pages/Dashmmcoursesadmin';
import Dashmmstudents from './pages/Dashmmstudents';
import Dashmmstudentsadmin from './pages/Dashmmstudentsadmin';
import Dashmexamtimetable from './pages/Dashmexamtimetable';
import Dashmexamtimetableadmin from './pages/Dashmexamtimetableadmin';

import Dashmmfaccourses from './pages/Dashmmfaccourses';
import Dashmmfaccoursesadmin from './pages/Dashmmfaccoursesadmin';
import Dashmmfaccoursesatt from './pages/Dashmmfaccoursesatt';
import Dashmmfaccoursesattadmin from './pages/Dashmmfaccoursesattadmin';

import Dashmmattcalc from './pages/Dashmmattcalc';
import Dashmmattcalcadmin from './pages/Dashmmattcalcadmin';

import Dashmmcolevels from './pages/Dashmmcolevels';
import Dashmmcolevelsadmin from './pages/Dashmmcolevelsadmin';

import Dashmmcolevelscalc from './pages/Dashmmcolevelscalc';

import Dashmmcourseslist from './pages/Dashmmcourseslist';
import Dashmmcourseslistadmin from './pages/Dashmmcourseslistadmin';

import Dashmmstudents1 from './pages/Dashmmstudents1';
import Dashmmstudents1admin from './pages/Dashmmstudents1admin';

import Dashmclassenr1 from './pages/Dashmclassenr1';
import Dashmclassenr1admin from './pages/Dashmclassenr1admin';

import Dashmclassenr1stud from './pages/Dashmclassenr1stud';

import Dashmmsyllabus from './pages/Dashmmsyllabus';
import Dashmmsyllabusstud from './pages/Dashmmsyllabusstud';
import Dashmmsyllabusadmin from './pages/Dashmmsyllabusadmin';

import Dashmmassignments from './pages/Dashmmassignments';
import Dashmmassignmentsadmin from './pages/Dashmmassignmentsadmin';
import Dashmmanouncements from './pages/Dashmmanouncements';
import Dashmmanouncementsadmin from './pages/Dashmmanouncementsadmin';
import Dashmmcourseco from './pages/Dashmmcourseco';
import Dashmmcoursecoadmin from './pages/Dashmmcoursecoadmin';
import Dashmmcalendar from './pages/Dashmmcalendar';
import Dashmmcalendaradmin from './pages/Dashmmcalendaradmin';
import Dashmmcoursematerial from './pages/Dashmmcoursematerial';
import Dashmmcoursematerialadmin from './pages/Dashmmcoursematerialadmin';

import Dashmmannouncementsstud from './pages/Dashmmannouncementsstud';
import Dashmmassignmentsstud from './pages/Dashmmassignmentsstud';
import Dashmmcalendarstud from './pages/Dashmmcalendarstud';
import Dashmmcoursematerialstud from './pages/Dashmmcoursematerialstud';
import Dashmmcoursecostud from './pages/Dashmmcoursecostud';

import Dashmmassignsubmit from './pages/Dashmmassignsubmit';
import Dashmmassignsubmitadmin from './pages/Dashmmassignsubmitadmin';
import Dashmmdiscussion from './pages/Dashmmdiscussion';
import Dashmmdiscussionadmin from './pages/Dashmmdiscussionadmin';

import Dashmmassignsubmitfac from './pages/Dashmmassignsubmitfac';

import Dashmquotanew from './pages/Dashmquotanew';
import Dashmquotanewadmin from './pages/Dashmquotanewadmin';

// import Resistor from './pages/Resistor';
// import Bubblesort from './pages/BubbleSort';
// import Firstsimulator from './pages/FirstSimulator';
// import SecondSimulator from './pages/SecondSimulator';

import Dashmclassnew from './pages/Dashmclassnew';
import Dashmclassnewadmin from './pages/Dashmclassnewadmin';
import Dashmattendancenew from './pages/Dashmattendancenew';
import Dashmattendancenewadmin from './pages/Dashmattendancenewadmin';

import SubHalfAdder1 from './pages/SubHalfAdder1';
import BasicLogicGateExpFirst from './pages/BasicLogicGateExpFirst';
import BasicLogicGateExpSecond from './pages/BasicLogicGateExpSecond';
import HalfSubtractor from './pages/HalfSubtractor';
import FullSubtractor from './pages/FullSubtractor';
import TwoBitAdder from './pages/TwoBitAdder';

import Dashmattccode from './pages/Dashmattccode';
import Dashmattpcode from './pages/Dashmattpcode';
import Dashmclassnewc from './pages/Dashmclassnewc';

import Dashmclassnewstud from './pages/Dashmclassnewstud';
import FourBitAdderSubtractor from './pages/FourBitAdderSubtractor';

import Dashmstudawardsnew from './pages/Dashmstudawardsnew';
import Dashmstudawardsnewadmin from './pages/Dashmstudawardsnewadmin';
import Dashmscholnew from './pages/Dashmscholnew';
import Dashmscholnewadmin from './pages/Dashmscholnewadmin';
import Dashmscholnewstud from './pages/Dashmscholnewstud';
import Dashmstudawardsnewstud from './pages/Dashmstudawardsnewstud';

import Dashmslideshow from './pages/Dashmslideshow';
import Dashmslideshowadmin from './pages/Dashmslideshowadmin';

import Dashmeventsnew1 from './pages/Dashmeventsnew1';
import Dashmeventsnew1admin from './pages/Dashmeventsnew1admin';
import Dashmpolicy from './pages/Dashmpolicy';

import Dashmqualitative from './pages/Dashmqualitative';

import Dashmtestnew from './pages/Dashmtestnew';
import Dashmtestnewadmin from './pages/Dashmtestnewadmin';
import Dashmtestq from './pages/Dashmtestq';
import Dashmtestqadmin from './pages/Dashmtestqadmin';
import Dashmtesto from './pages/Dashmtesto';
import Dashmtestoadmin from './pages/Dashmtestoadmin';

import CharacterizationOfDigitalLogic from './pages/CharacterizationOfDigitalLogic';

import InsertionSort from './pages/InsertionSort';
import SelectionSort from './pages/SelectionSort';

import Dashmtestnewstud from './pages/Dashmtestnewstud';

import StackVisualization from './pages/StackVisualization';
import ArrayVisualization from './pages/ArrayVisualization';
import BinaryArithmeticSimulation from './pages/BinaryArithmeticSimulation';

import QueueVisualization from './pages/QueueVisualization';
import BinarySearch from './pages/BinarySearch';
import BinaryArithmeticS from './pages/BinaryArithmeticS';

import NOTGate from './pages/NOTGate';
import NOTGate2 from './pages/NOTGate2';
import ANDGate from './pages/ANDGate';
import ANDGate2 from './pages/ANDGate2';
import ORGate from './pages/ORGate';
import ORGate2 from './pages/ORGate2';

import Dashmtestqstud from './pages/Dashmtestqstud';

import NANDGate from './pages/NANDGate';
import NANDGate2 from './pages/NANDGate2';
import NORGate from './pages/NORGate';
import NORGate2 from './pages/NORGate2';

import Dashmgeotagtest from './pages/Dashmgeotagtest';

import XORGate from './pages/XORGate';
import HalfSubtractorCircuit from './pages/HalfSubtractorCircuit';

import XNORGate from './pages/XNORGate';
import XNORGate2 from './pages/XNORGate2';
import XORGate2 from './pages/XORGate2';

import FullSubCircuitVerify from './pages/FullSubCircuitVerify';
import FullSubtractorCircuit from './pages/FullSubtractorCircuit';
import HalfSubCircuitVerify from './pages/HalfSubCircuitVerify';

import VideoPage from './pages/VideoPage';

import CodeEditor from './pages/CodeEditor';
import BCDToExcessConverter from './pages/BCDToExcessConverter';
import BitSerial from './pages/BitSerial';
import GrayToBinaryConverter from './pages/GrayToBinaryConverter';

import Dashmlmsvideos from './pages/Dashmlmsvideos';
import Dashmlmsvideosadmin from './pages/Dashmlmsvideosadmin';
import Dashmlmsvideosc from './pages/Dashmlmsvideosc';
import Dashmlmsvideoscadmin from './pages/Dashmlmsvideoscadmin';

import VideoPage2 from './pages/VideoPage2';
import Videopagepre from './pages/Videopagepre';
import Videopage3 from './pages/Videopage3';
import Videopage31 from './pages/Videopage31';

import Videopagepreshare from './pages/Videopagepreshare';

import Dashmhtmleditor from './pages/Dashmhtmleditor';

import Circulareventsm from './pages/Circulareventsm';

import CampusWebsite from './pages/CampusWebsite';

import SignupPage from './pages/SignupPage';
import Signinpage from './pages/Signinpage';

import Dashmmvac from './pages/Dashmmvac';
import Dashmmvacadmin from './pages/Dashmmvacadmin';

import Dashawsconfig from './pages/Dashawsconfig';

import Dashmtestscoreall from './pages/Dashmtestscoreall';
import StefansLaw from './pages/StefansLaw';
import Videopage32 from './pages/Videopage32';
import Videopage32a from './pages/Videopage32a';
import PhaseSequenceSynchronous from './pages/PhaseSequenceSynchronous';

import OpticalFibre from './pages/OpticalFibre';
import TransformerOilStrength from './pages/TransformerOilStrength';

import Dashmpassword from './pages/Dashmpassword';
import Dashmpasswordstud from './pages/Dashmpasswordstud';


import Dashmlpublications from './pages/Dashmlpublications';
import Dashmlpublicationsadmin from './pages/Dashmlpublicationsadmin';
import Dashmlpubeditions from './pages/Dashmlpubeditions';
import Dashmlpubeditionsadmin from './pages/Dashmlpubeditionsadmin';
import Dashmlpubreviews from './pages/Dashmlpubreviews';
import Dashmlpubreviewsadmin from './pages/Dashmlpubreviewsadmin';
import Dashmlpubarticles from './pages/Dashmlpubarticles';
import Dashmlpubarticlesadmin from './pages/Dashmlpubarticlesadmin';

import Dashmlpublicationspublic from './pages/Dashmlpublicationspublic';
import Dashmlpubeditionspublic from './pages/Dashmlpubeditionspublic';

import DigitalTriradii from './pages/DigitalTriradii';
import FingerPrintPatterns from './pages/FingerPrintPatterns';

import DCShuntMotorSimulation from './pages/DCShuntMotorSimulation';
import SkeletonExp from './pages/SkeletonExp';

import Titration from './pages/Titration';
import SkeletonExpPart2 from './pages/SkeletonExpPart2';
import InfraRedSpectros from './pages/InfraRedSpectros';

import SkeletonExpPart2Game from './pages/SkeletonExpPart2Game';
import GetMoldGame from './pages/GetMoldGame';
import OpticalFibreGame from './pages/OpticalFibreGame';
import DigitalTriradiiGame from './pages/DigitalTriradiiGame';

import TransformerOilStrengthGame from './pages/TransformerOilStrengthGame';
import TitrationGame from './pages/TitrationGame';
import InfraRedSpectrosGame from './pages/InfraRedSpectrosGame';
import Dashmmplacement from './pages/Dashmmplacement';
import Dashmmplacementadmin from './pages/Dashmmplacementadmin';

import Dashmmassets from './pages/Dashmmassets';
import Dashmmassetsadmin from './pages/Dashmmassetsadmin';
import Dashmmassetassign from './pages/Dashmmassetassign';
import Dashmmassetassignadmin from './pages/Dashmmassetassignadmin';
import Dashmmvendors from './pages/Dashmmvendors';
import Dashmmvendorsadmin from './pages/Dashmmvendorsadmin';
import Dashmmvendorbanks from './pages/Dashmmvendorbanks';
import Dashmmvendorbanksadmin from './pages/Dashmmvendorbanksadmin';
import Dashmmpurchase from './pages/Dashmmpurchase';
import Dashmmpurchaseadmin from './pages/Dashmmpurchaseadmin';
import Dashmmpurchaseitems from './pages/Dashmmpurchaseitems';
import Dashmmpurchaseitemsadmin from './pages/Dashmmpurchaseitemsadmin';
import Dashmmpopayments from './pages/Dashmmpopayments';
import Dashmmpopaymentsadmin from './pages/Dashmmpopaymentsadmin';

import BCDToExcessConverterGame from './pages/BCDToExcessConverterGame';
// import BinaryToGrayConverterGame from './pages/BinaryToGrayConverterGame';
// import DCShuntMotorSimulationGame from './pages/DCShuntMotorSimulationGame';
import FullSubCircuitVerifyGame from './pages/FullSubCircuitVerifyGame';
import FullSubtractorCircuitGame from './pages/FullSubtractorCircuitGame';
import GrayToBinaryConverterGame from './pages/GrayToBinaryConverterGame';
import HalfSubCircuitVerifyGame from './pages/HalfSubCircuitVerifyGame';
import HalfSubtractorCircuitGame from './pages/HalfSubtractorCircuitGame';

import ANDGateGame from './pages/ANDGateGame';
import BitSerialGame from './pages/BitSerialGame';
import FingerPrintPatternsGame from './pages/FingerPrintPatternsGame';
import NANDGateGame from './pages/NANDGateGame';
import NORGateGame from './pages/NORGateGame';

import NOTGateGame from './pages/NOTGateGame.js';
// import OpticalFibreGame from './pages/OpticalFibreGame.js';
import ORGateGame from './pages/ORGateGame.js';
// import ResistorGame from './pages/ResistorGame.js';
import SkeletonExpGame from './pages/SkeletonExpGame.js';
// import SkeletonExpPart2Game from './pages/SkeletonExpPart2Game.js';
import StefansLawGame from './pages/StefansLawGame.js';
import SubHalfAdder1Game from './pages/SubHalfAdder1Game.js';
import XNORGateGame from './pages/XNORGateGame.js';
import XORGateGame from './pages/XORGateGame.js';

import Dashmstudgender from './pages/Dashmstudgender.js';
import Dashmstudcategory from './pages/Dashmstudcategory.js';
import Dashmstudquota from './pages/Dashmstudquota.js';

import FindDiff from './pages/FindDiff.js';
import RaceGame from './pages/RaceGame.js';
import WordGuessing from './pages/WordGuessing.js';
import ImgPuzzle from './pages/ImgPuzzle.js';
import PacManGame from './pages/PacMan.js';
import BetterAimGame2 from './pages/BatterAim.js';

import TetrisGame from './pages/TetrisGame.js';
// import DiceGame from './pages/DiceGame.js';
import SudokuGame from './pages/SudokuGame.js';
import TowerOfHanoi from './pages/TowerOfHanoi.js';
import UltimateBattleGame from './pages/UltimateBattleGame.js';
// import MazeGen from './pages/MazeGen.js';

import Dashmmtestnewm from './pages/Dashmmtestnewm';
import Dashmmtestnewmadmin from './pages/Dashmmtestnewmadmin';
import Dashmmtestseenrol from './pages/Dashmmtestseenrol';
import Dashmmtestseenroladmin from './pages/Dashmmtestseenroladmin';
import Dashmmtestsections from './pages/Dashmmtestsections';
import Dashmmtestsectionsadmin from './pages/Dashmmtestsectionsadmin';
import Dashmmtestqnew from './pages/Dashmmtestqnew';
import Dashmmtestqnewadmin from './pages/Dashmmtestqnewadmin';
import Dashmmtestsessions from './pages/Dashmmtestsessions';
import Dashmmtestsessionsadmin from './pages/Dashmmtestsessionsadmin';

import Dashmmtestsections1 from './pages/Dashmmtestsections1';
import Dashmmtestsections1admin from './pages/Dashmmtestsections1admin';

import Dashmmtestseenrol1 from './pages/Dashmmtestseenrol1';
import Dashmmtestseenrol1admin from './pages/Dashmmtestseenrol1admin';

import CampusTalent from './pages/CampusTalent';

import Dashmtestscorenew from './pages/Dashmtestscorenew.js';

import Dashmmguides from './pages/Dashmmguides';
import Dashmmguidesadmin from './pages/Dashmmguidesadmin';
import Dashmmctalentreg from './pages/Dashmmctalentreg';
import Dashmmctalentregadmin from './pages/Dashmmctalentregadmin';
import Dashmmtestqnewcs from './pages/Dashmmtestqnewcs';
import Dashmmtestqnewcsadmin from './pages/Dashmmtestqnewcsadmin';

import CampusTalentRegister from './pages/CampusTalentRegister';
import CampusTalent1 from './pages/CampusTalent1.js';

import Dashmonlinepay from './pages/Dashmonlinepay';
import Dashmonlinepayadmin from './pages/Dashmonlinepayadmin';

import CampusTalentRegister1 from './pages/CampusTalentRegister1';

import Signinpay from './pages/Signinpay';
import Forgotpassword from './pages/Forgotpassword';

import Dashmtestscoresnewall from './pages/Dashmtestscoresnewall';

import Deleteaccount from './pages/Deleteaccount';

import Viewmreactflow1 from './pages/Viewmreactflow1';
import Dashmreactflow1 from './pages/Dashmreactflow1';


import Dashmmindmaplist from './pages/Dashmmindmaplist';
import Dashmmindmaplistadmin from './pages/Dashmmindmaplistadmin';
import Dashmmindmapedges from './pages/Dashmmindmapedges';
import Dashmmindmapedgesadmin from './pages/Dashmmindmapedgesadmin';
import Dashmmindmapnodes from './pages/Dashmmindmapnodes';
import Dashmmindmapnodesadmin from './pages/Dashmmindmapnodesadmin';

import Viewmindmap from './pages/Viewmindmap';
import Viewmindmap1 from './pages/Viewmindmap1';
import Viewmindmap2 from './pages/Viewmindmap2';












function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/" element={<CampusWebsite />} />
        <Route path="/viewcourse1" element={<Viewcourse1 />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/learning-management-system" element={<LearningManagementSystem />} />
        <Route path="/naac" element={<NAAC />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/Parct1" element={<Parct1 />} />
        <Route path="/add" element={<AddUser />} />
        <Route path="/edit/:id" element={<EditUser />} />
        <Route path="/delete/:id" element={<DeleteUser />} />
        {/* <Route path="/users" element={<Users />} /> */}
        <Route path="/export" element={<ExportUsers />} />
        <Route path="/dashmcas11" element={<Dashmcas11 />} />
        <Route path="/dashmncas11" element={<Dashmncas11 />} />
        <Route path="/dashmncas11admin" element={<Dashmncas11admin />} />
        <Route path="/Login3" element={<Login />} />
        <Route path="/dashmncas12" element={<Dashmncas12 />} />
        <Route path="/dashmncas12admin" element={<Dashmncas12admin />} />


        <Route path="/viewmindmap" element={<Viewmindmap2 />} />
        <Route path="/dashmmindmaplist" element={<Dashmmindmaplist />} />
        <Route path="/dashmmindmaplistadmin" element={<Dashmmindmaplistadmin />} />
        <Route path="/dashmmindmapedges" element={<Dashmmindmapedges />} />
        <Route path="/dashmmindmapedgesadmin" element={<Dashmmindmapedgesadmin />} />
        <Route path="/dashmmindmapnodes" element={<Dashmmindmapnodes />} />
        <Route path="/dashmmindmapnodesadmin" element={<Dashmmindmapnodesadmin />} />


        <Route path="/dashmreactflow1" element={<Dashmreactflow1 />} />
        <Route path="/viewmreactflow1" element={<Viewmreactflow1 />} />
        <Route path="/deleteaccount" element={<Deleteaccount />} />
        <Route path="/dashmtestscoresnewall" element={<Dashmtestscoresnewall />} />
        
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/signinpay" element={<Signinpay />} />

        <Route path="/campustalentregister1" element={<CampusTalentRegister1 />} />

        <Route path="/dashmonlinepay" element={<Dashmonlinepay />} />
        <Route path="/dashmonlinepayadmin" element={<Dashmonlinepayadmin />} />


        <Route path="/campustalentregister" element={<CampusTalentRegister />} />

        <Route path="/dashmmctalentreg" element={<Dashmmctalentreg />} />
        <Route path="/dashmmctalentregadmin" element={<Dashmmctalentregadmin />} />
        <Route path="/dashmmtestqnewcs" element={<Dashmmtestqnewcs />} />
        <Route path="/dashmmtestqnewcsadmin" element={<Dashmmtestqnewcsadmin />} />
        <Route path="/dashmmguides" element={<Dashmmguides />} />
        <Route path="/dashmmguidesadmin" element={<Dashmmguidesadmin />} />


        <Route path="/dashmtestscorenew" element={<Dashmtestscorenew />} />
        <Route path="/campustalent" element={<CampusTalent1 />} />
        <Route path="/dashmmtestsections1" element={<Dashmmtestsections1 />} />
        <Route path="/dashmmtestsections1admin" element={<Dashmmtestsections1admin />} />
        <Route path="/dashmmtestseenrol1" element={<Dashmmtestseenrol1 />} />
        <Route path="/dashmmtestseenrol1admin" element={<Dashmmtestseenrol1admin />} />



        <Route path="/dashmmtestnewm" element={<Dashmmtestnewm />} />
        <Route path="/dashmmtestnewmadmin" element={<Dashmmtestnewmadmin />} />
        <Route path="/dashmmtestsessions" element={<Dashmmtestsessions />} />
        <Route path="/dashmmtestsessionsadmin" element={<Dashmmtestsessionsadmin />} />
        <Route path="/dashmmtestseenrol" element={<Dashmmtestseenrol />} />
        <Route path="/dashmmtestseenroladmin" element={<Dashmmtestseenroladmin />} />
        <Route path="/dashmmtestqnew" element={<Dashmmtestqnew />} />
        <Route path="/dashmmtestqnewadmin" element={<Dashmmtestqnewadmin />} />
        <Route path="/dashmmtestsections" element={<Dashmmtestsections />} />
        <Route path="/dashmmtestsectionsadmin" element={<Dashmmtestsectionsadmin />} />


        {/* <Route path="/mazegen" element={<MazeGen />} /> */}
        <Route path="/ultimatebattlegame" element={<UltimateBattleGame />} />
        <Route path="/sudokugame" element={<SudokuGame />} />
        <Route path="/towerofhanoi" element={<TowerOfHanoi />} />
        {/* <Route path="/dicegame" element={<DiceGame />} /> */}
        <Route path="/tetrisgame" element={<TetrisGame />} />
        <Route path="/finddiff" element={<FindDiff />} />
        <Route path="/racegame" element={<RaceGame />} />
        <Route path="/wordguessing" element={<WordGuessing />} />
        <Route path="/imgpuzzle" element={<ImgPuzzle />} />
        <Route path="/pacmangame" element={<PacManGame />} />
        <Route path="/betteraimgame" element={<BetterAimGame2 />} />

        <Route path="/dashmstudquota" element={<Dashmstudquota />} />
        <Route path="/dashmstudcategory" element={<Dashmstudcategory />} />
        <Route path="/dashmstudgender" element={<Dashmstudgender />} />
        <Route path="/notgategame" element={<NOTGateGame />} />
        {/* <Route path="/opticalfibregame" element={<OpticalFibreGame />} /> */}
        <Route path="/orgategame" element={<ORGateGame />} />
        {/* <Route path="/resistorgame" element={<ResistorGame />} /> */}
        <Route path="/skeletonexpgame" element={<SkeletonExpGame />} />
        {/* <Route path="/skeletonexppart2game" element={<SkeletonExpPart2Game />} /> */}
        <Route path="/stefanslawgame" element={<StefansLawGame />} />
        <Route path="/subhalfadder1game" element={<SubHalfAdder1Game />} />
        <Route path="/xnorgategame" element={<XNORGateGame />} />
        <Route path="/xorgategame" element={<XORGateGame />} />

        <Route path="/norgategame" element={<NORGateGame />} />
        <Route path="/nandgategame" element={<NANDGateGame />} />
        <Route path="/andgategame" element={<ANDGateGame />} />
        <Route path="/bitserialgame" element={<BitSerialGame />} />
        <Route path="/fingerprintpatterngames" element={<FingerPrintPatternsGame />} />


        <Route path="/bcdtoexcessgames" element={<BCDToExcessConverterGame />} />
        <Route path="/halfsubcircuitverifygame" element={<HalfSubCircuitVerifyGame />} />
        {/* <Route path="/binarytograygames" element={<BinaryToGrayConverterGame />} />
        <Route path="/dcshuntgames" element={<DCShuntMotorSimulationGame />} /> */}
        <Route path="/fullsubcircuitverifygame" element={<FullSubCircuitVerifyGame />} />
        <Route path="/fullsubtractorcircuitgame" element={<FullSubtractorCircuitGame />} />
        <Route path="/graytobinaryconvertedgame" element={<GrayToBinaryConverterGame />} />
        <Route path="/halfsubtractorcircuitgame" element={<HalfSubtractorCircuitGame />} />


        <Route path="/dashmmassets" element={<Dashmmassets />} />
        <Route path="/dashmmassetsadmin" element={<Dashmmassetsadmin />} />
        <Route path="/dashmmassetassign" element={<Dashmmassetassign />} />
        <Route path="/dashmmassetassignadmin" element={<Dashmmassetassignadmin />} />
        <Route path="/dashmmvendors" element={<Dashmmvendors />} />
        <Route path="/dashmmvendorsadmin" element={<Dashmmvendorsadmin />} />
        <Route path="/dashmmvendorbanks" element={<Dashmmvendorbanks />} />
        <Route path="/dashmmvendorbanksadmin" element={<Dashmmvendorbanksadmin />} />
        <Route path="/dashmmpurchase" element={<Dashmmpurchase />} />
        <Route path="/dashmmpurchaseadmin" element={<Dashmmpurchaseadmin />} />
        <Route path="/dashmmpurchaseitems" element={<Dashmmpurchaseitems />} />
        <Route path="/dashmmpurchaseitemsadmin" element={<Dashmmpurchaseitemsadmin />} />
        <Route path="/dashmmpopayments" element={<Dashmmpopayments />} />
        <Route path="/dashmmpopaymentsadmin" element={<Dashmmpopaymentsadmin />} />


        <Route path="/dashmmplacement" element={<Dashmmplacement />} />
        <Route path="/dashmmplacementadmin" element={<Dashmmplacementadmin />} />

        <Route path="/transformeroilgame" element={<TransformerOilStrengthGame />} />
        <Route path="/titrationgame" element={<TitrationGame />} />
        <Route path="/infraredgame" element={<InfraRedSpectrosGame />} />

        <Route path="/skeletonpart2game" element={<SkeletonExpPart2Game />} />
        <Route path="/getmoldgame" element={<GetMoldGame />} />
        <Route path="/opticalfibregame" element={<OpticalFibreGame />} />
        <Route path="/digitaltriradiigame" element={<DigitalTriradiiGame />} />


        <Route path="/infraredspectros" element={<InfraRedSpectros />} />
        <Route path="/skeletonpart2" element={<SkeletonExpPart2 />} />
        <Route path="/titration" element={<Titration />} />
        <Route path="/skeletonexp" element={<SkeletonExp />} />
        <Route path="/dcshuntmotor" element={<DCShuntMotorSimulation />} />
        <Route path="/fingerprintpatterns" element={<FingerPrintPatterns />} />
        <Route path="/digitaltriradii" element={<DigitalTriradii />} />
        <Route path="/dashmlpubeditionspublic" element={<Dashmlpubeditionspublic />} />
        <Route path="/dashmlpublicationspublic" element={<Dashmlpublicationspublic />} />
        <Route path="/dashmlpublications" element={<Dashmlpublications />} />
        <Route path="/dashmlpublicationsadmin" element={<Dashmlpublicationsadmin />} />
        <Route path="/dashmlpubeditions" element={<Dashmlpubeditions />} />
        <Route path="/dashmlpubeditionsadmin" element={<Dashmlpubeditionsadmin />} />
        <Route path="/dashmlpubreviews" element={<Dashmlpubreviews />} />
        <Route path="/dashmlpubreviewsadmin" element={<Dashmlpubreviewsadmin />} />
        <Route path="/dashmlpubarticles" element={<Dashmlpubarticles />} />
        <Route path="/dashmlpubarticlesadmin" element={<Dashmlpubarticlesadmin />} />


        <Route path="/dashmpasswordstud" element={<Dashmpasswordstud />} />
        <Route path="/dashmpassword" element={<Dashmpassword />} />
        <Route path="/transformeroilstrength" element={<TransformerOilStrength />} />
        <Route path="/opticalfibre" element={<OpticalFibre />} />
        <Route path="/stephanslaw" element={<StefansLaw />} />
        <Route path="/phasesequence" element={<PhaseSequenceSynchronous />} />

        <Route path="/dashmtestscoreall" element={<Dashmtestscoreall />} />
        <Route path="/dashawsconfig" element={<Dashawsconfig />} />

        <Route path="/dashmmvac" element={<Dashmmvac />} />
        <Route path="/dashmmvacadmin" element={<Dashmmvacadmin />} />


        <Route path="/Login" element={<Signinpage />} />
        <Route path="/signuppage" element={<SignupPage />} />
        <Route path="/campuswebsite" element={<CampusWebsite />} />
        <Route path="/circulareventsm" element={<Circulareventsm />} />
        <Route path="/dashmhtmleditor" element={<Dashmhtmleditor />} />
        <Route path="/videoshare/:videoid" element={<Videopagepreshare />} />
        <Route path="/videopage31" element={<Videopage32a />} />
        {/* <Route path="/videopage31" element={<Videopage31 />} /> */}
        <Route path="/videopage3" element={<Videopage3 />} />
        <Route path="/videopagepre" element={<Videopagepre />} />
        <Route path="/videopage2" element={<VideoPage2 />} />

        <Route path="/dashmlmsvideos" element={<Dashmlmsvideos />} />
        <Route path="/dashmlmsvideosadmin" element={<Dashmlmsvideosadmin />} />
        <Route path="/dashmlmsvideosc" element={<Dashmlmsvideosc />} />
        <Route path="/dashmlmsvideoscadmin" element={<Dashmlmsvideoscadmin />} />


        <Route path="/graytobinaryconverter" element={<GrayToBinaryConverter />} />
        <Route path="/bitserial" element={<BitSerial />} />
        <Route path="/bcdtoexcessconverter" element={<BCDToExcessConverter />} />
        <Route path="/codeeditor" element={<CodeEditor />} />
        <Route path="/videopage" element={<VideoPage />} />

        <Route path="/fullsubcircuitverify" element={<FullSubCircuitVerify />} />
        <Route path="/fullsubtractorcircuit" element={<FullSubtractorCircuit />} />
        <Route path="/halfsubcircuitverify" element={<HalfSubCircuitVerify />} />

        <Route path="/xnorgate" element={<XNORGate />} />
        <Route path="/xnorgate2" element={<XNORGate2 />} />
        <Route path="/xorgate2" element={<XORGate2 />} />
        
        <Route path="/halfsubtractorcircuit" element={<HalfSubtractorCircuit />} />
        <Route path="/xorgate" element={<XORGate />} />
        <Route path="/dashmgeotagtest" element={<Dashmgeotagtest />} />

        <Route path="/nandgate" element={<NANDGate />} />
        <Route path="/nandgate2" element={<NANDGate2 />} />
        <Route path="/norgate" element={<NORGate />} />
        <Route path="/norgate2" element={<NORGate2 />} />

        <Route path="/dashmtestqstud" element={<Dashmtestqstud />} />

        <Route path="/andgate" element={<ANDGate />} />
        <Route path="/andgate2" element={<ANDGate2 />} />
        <Route path="/orgate" element={<ORGate />} />
        <Route path="/orgate2" element={<ORGate2 />} />
        <Route path="/notgate" element={<NOTGate />} />
        <Route path="/notgate2" element={<NOTGate2 />} />


        <Route path="/binaryarithmetics" element={<BinaryArithmeticS />} />
        <Route path="/queuevisual" element={<QueueVisualization />} />
        <Route path="/binarysearch" element={<BinarySearch />} />

        <Route path="/binaryarith" element={<BinaryArithmeticSimulation />} />
        <Route path="/stackvisualization" element={<StackVisualization />} />
        <Route path="/arrayvisualization" element={<ArrayVisualization />} />

        <Route path="/dashmtestnewstud" element={<Dashmtestnewstud />} />
        
        <Route path="/codl" element={<CharacterizationOfDigitalLogic />} />
        
        <Route path="/insertionsort" element={<InsertionSort />} />
        <Route path="/selectionsort" element={<SelectionSort />} />

        <Route path="/dashmtestnew" element={<Dashmtestnew />} />
        <Route path="/dashmtestnewadmin" element={<Dashmtestnewadmin />} />
        <Route path="/dashmtestq" element={<Dashmtestq />} />
        <Route path="/dashmtestqadmin" element={<Dashmtestqadmin />} />
        <Route path="/dashmtesto" element={<Dashmtesto />} />
        <Route path="/dashmtestoadmin" element={<Dashmtestoadmin />} />


        <Route path="/dashmqualitative" element={<Dashmqualitative />} />
        <Route path="/dashmpolicy" element={<Dashmpolicy />} />
        <Route path="/dashmeventsnew1" element={<Dashmeventsnew1 />} />
        <Route path="/dashmeventsnew1admin" element={<Dashmeventsnew1admin />} />


        <Route path="/dashmslideshow" element={<Dashmslideshow />} />
        <Route path="/dashmslideshowadmin" element={<Dashmslideshowadmin />} />

        
        <Route path="/dashmscholnew" element={<Dashmscholnew />} />
        <Route path="/dashmscholnewadmin" element={<Dashmscholnewadmin />} />
        <Route path="/dashmstudawardsnew" element={<Dashmstudawardsnew />} />
        <Route path="/dashmstudawardsnewadmin" element={<Dashmstudawardsnewadmin />} />
        <Route path="/dashmscholnewstud" element={<Dashmscholnewstud />} />
        <Route path="/dashmstudawardsnewstud" element={<Dashmstudawardsnewstud />} />



        <Route path="/fourbitaddersubtractor" element={<FourBitAdderSubtractor />} />
        <Route path="/dashmclassnewstud" element={<Dashmclassnewstud />} />
        <Route path="/dashmclassnewc" element={<Dashmclassnewc />} />
        <Route path="/dashmattccode" element={<Dashmattccode />} />
        <Route path="/dashmattpcode" element={<Dashmattpcode />} />

        <Route path="/subhalfadder1" element={<SubHalfAdder1 />} />
        <Route path="/basiclogicgateexpfirst" element={<BasicLogicGateExpFirst />} />
        <Route path="/basiclogicgateexpsecond" element={<BasicLogicGateExpSecond />} />
        <Route path="/fullsubtractor" element={<FullSubtractor />} />
        <Route path="/halfsubtractor" element={<HalfSubtractor />} />
        <Route path="/twobitadder" element={<TwoBitAdder />} />

        <Route path="/dashmclassnew" element={<Dashmclassnew />} />
<Route path="/dashmclassnewadmin" element={<Dashmclassnewadmin />} />
<Route path="/dashmattendancenew" element={<Dashmattendancenew />} />
<Route path="/dashmattendancenewadmin" element={<Dashmattendancenewadmin />} />


        {/* <Route path="/resistor" element={<Resistor />} />
        <Route path="/bubblesort" element={<Bubblesort />} />
        <Route path="/firstsimulator" element={<Firstsimulator />} />
        <Route path="/secondsimulator" element={<SecondSimulator />} /> */}


        <Route path="/dashmquotanew" element={<Dashmquotanew />} />
<Route path="/dashmquotanewadmin" element={<Dashmquotanewadmin />} />


        <Route path="/dashmmassignsubmitfac" element={<Dashmmassignsubmitfac />} />

        <Route path="/dashmmassignsubmit" element={<Dashmmassignsubmit />} />
<Route path="/dashmmassignsubmitadmin" element={<Dashmmassignsubmitadmin />} />
<Route path="/dashmmdiscussion" element={<Dashmmdiscussion />} />
<Route path="/dashmmdiscussionadmin" element={<Dashmmdiscussionadmin />} />


        <Route path="/dashmmcoursecostud" element={<Dashmmcoursecostud />} />
        <Route path="/dashmmcoursematerialstud" element={<Dashmmcoursematerialstud />} />
        <Route path="/dashmmcalendarstud" element={<Dashmmcalendarstud />} />
        <Route path="/dashmmassignmentsstud" element={<Dashmmassignmentsstud />} />
        <Route path="/dashmmanouncementsstud" element={<Dashmmannouncementsstud />} />

        <Route path="/dashmmassignments" element={<Dashmmassignments />} />
<Route path="/dashmmassignmentsadmin" element={<Dashmmassignmentsadmin />} />
<Route path="/dashmmanouncements" element={<Dashmmanouncements />} />
<Route path="/dashmmanouncementsadmin" element={<Dashmmanouncementsadmin />} />
<Route path="/dashmmcourseco" element={<Dashmmcourseco />} />
<Route path="/dashmmcoursecoadmin" element={<Dashmmcoursecoadmin />} />
<Route path="/dashmmcalendar" element={<Dashmmcalendar />} />
<Route path="/dashmmcalendaradmin" element={<Dashmmcalendaradmin />} />
<Route path="/dashmmcoursematerial" element={<Dashmmcoursematerial />} />
<Route path="/dashmmcoursematerialadmin" element={<Dashmmcoursematerialadmin />} />


        <Route path="/dashmmsyllabus" element={<Dashmmsyllabus />} />
        
<Route path="/dashmmsyllabusadmin" element={<Dashmmsyllabusadmin />} />

<Route path="/dashmmsyllabusstud" element={<Dashmmsyllabusstud />} />

        <Route path="/dashmclassenr1" element={<Dashmclassenr1 />} />
<Route path="/dashmclassenr1admin" element={<Dashmclassenr1admin />} />
<Route path="/dashmclassenr1stud" element={<Dashmclassenr1stud />} />



        <Route path="/dashmmstudents1" element={<Dashmmstudents1 />} />
<Route path="/dashmmstudents1admin" element={<Dashmmstudents1admin />} />


        <Route path="/dashmmcourseslist" element={<Dashmmcourseslist />} />
<Route path="/dashmmcourseslistadmin" element={<Dashmmcourseslistadmin />} />


        <Route path="/dashmmcolevelscalc" element={<Dashmmcolevelscalc />} />

        <Route path="/dashmmcolevels" element={<Dashmmcolevels />} />
<Route path="/dashmmcolevelsadmin" element={<Dashmmcolevelsadmin />} />


        <Route path="/dashmmattcalc" element={<Dashmmattcalc />} />
<Route path="/dashmmattcalcadmin" element={<Dashmmattcalcadmin />} />


        <Route path="/dashmmfaccourses" element={<Dashmmfaccourses />} />
<Route path="/dashmmfaccoursesadmin" element={<Dashmmfaccoursesadmin />} />
<Route path="/dashmmfaccoursesatt" element={<Dashmmfaccoursesatt />} />
<Route path="/dashmmfaccoursesattadmin" element={<Dashmmfaccoursesattadmin />} />



        <Route path="/dashmmprograms" element={<Dashmmprograms />} />
<Route path="/dashmmprogramsadmin" element={<Dashmmprogramsadmin />} />
<Route path="/dashmmcourses" element={<Dashmmcourses />} />
<Route path="/dashmmcoursesadmin" element={<Dashmmcoursesadmin />} />
<Route path="/dashmmstudents" element={<Dashmmstudents />} />
<Route path="/dashmmstudentsadmin" element={<Dashmmstudentsadmin />} />
<Route path="/dashmexamtimetable" element={<Dashmexamtimetable />} />
<Route path="/dashmexamtimetableadmin" element={<Dashmexamtimetableadmin />} />


        
        <Route path="/loginstud" element={<Loginstud />} />
        <Route path="/dashmexamschedule" element={<Dashmexamschedule />} />
<Route path="/dashmexamscheduleadmin" element={<Dashmexamscheduleadmin />} />
<Route path="/dashmexamroom" element={<Dashmexamroom />} />
<Route path="/dashmexamroomadmin" element={<Dashmexamroomadmin />} />



        <Route path="/dashmpublications" element={<Dashmpublications />} />
<Route path="/dashmpublicationsadmin" element={<Dashmpublicationsadmin />} />
<Route path="/dashmpatents" element={<Dashmpatents />} />
<Route path="/dashmpatentsadmin" element={<Dashmpatentsadmin />} />
<Route path="/dashmteacherfellow" element={<Dashmteacherfellow />} />
<Route path="/dashmteacherfellowadmin" element={<Dashmteacherfellowadmin />} />
<Route path="/dashmconsultancy" element={<Dashmconsultancy />} />
<Route path="/dashmconsultancyadmin" element={<Dashmconsultancyadmin />} />
<Route path="/dashmphdguide" element={<Dashmphdguide />} />
<Route path="/dashmphdguideadmin" element={<Dashmphdguideadmin />} />
<Route path="/dashmseminar" element={<Dashmseminar />} />
<Route path="/dashmseminaradmin" element={<Dashmseminaradmin />} />
<Route path="/dashmbook" element={<Dashmbook />} />
<Route path="/dashmbookadmin" element={<Dashmbookadmin />} />



        <Route path="/dashmprojects" element={<Dashmprojects />} />
<Route path="/dashmprojectsadmin" element={<Dashmprojectsadmin />} />



        <Route path="/dashmncas22" element={<Dashmncas22 />} />
<Route path="/dashmncas22admin" element={<Dashmncas22admin />} />
<Route path="/dashmncas241" element={<Dashmncas241 />} />
<Route path="/dashmncas241admin" element={<Dashmncas241admin />} />
<Route path="/dashmncas23" element={<Dashmncas23 />} />
<Route path="/dashmncas23admin" element={<Dashmncas23admin />} />
<Route path="/dashmncas242" element={<Dashmncas242 />} />
<Route path="/dashmncas242admin" element={<Dashmncas242admin />} />
<Route path="/dashmncas243" element={<Dashmncas243 />} />
<Route path="/dashmncas243admin" element={<Dashmncas243admin />} />
<Route path="/dashmncas251" element={<Dashmncas251 />} />
<Route path="/dashmncas251admin" element={<Dashmncas251admin />} />
<Route path="/dashmncas252" element={<Dashmncas252 />} />
<Route path="/dashmncas252admin" element={<Dashmncas252admin />} />
<Route path="/dashmncas253" element={<Dashmncas253 />} />
<Route path="/dashmncas253admin" element={<Dashmncas253admin />} />
<Route path="/dashmncas26" element={<Dashmncas26 />} />
<Route path="/dashmncas26admin" element={<Dashmncas26admin />} />



        
      </Routes>
    </Router>
  );
}

export default App;
